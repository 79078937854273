.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center;
  padding: 20px;
  background-color: #f8f8f8;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #9C27B0;
  padding: 10px;
  position: relative;
  z-index: 1; /* Ensure navbar is on top */
}

.home-button {
  position: absolute;
  left: 20px; /* Align the home button to the left */
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.header {
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin: 0;
}

/* Daily Quiz Banner */
.daily-quiz-banner {
  background-color: white; /* Make the background white */
  color: #9C27B0; /* Purple text */
  padding: 20px;
  margin-top: 10px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for better visual effect */
}

.daily-quiz-banner h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color:black;
}

.daily-quiz-banner p {
  margin: 0;
  font-size: 14px; /* Smaller font for the streak and category */
  color: #9C27B0;
}

.start-quiz-button {
  background-color: #9C27B0;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.start-quiz-button.completed {
  background-color: grey;
  color: white;
  cursor: not-allowed;
}

.start-quiz-button:hover {
  background-color: #7b1fa2;
}

.quiz-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the quizzes */
  max-width: 700px;
  width: 100%;
  margin: 30px auto 0; /* Ensure the sections are centered and spaced from the banner */
}

.section-container {
  margin-bottom: 30px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-header {
  display: flex;
  align-items: center;
}

.section-title {
  margin-left: 10px;
  font-size: 20px;
  color: #841584;
}

.quiz-list {
  margin-top: 20px;
}

.quiz-button {
  background-color: #9C27B0;
  color: white;
  padding: 10px 15px;
  margin: 5px 0;
  border: none;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quiz-button:hover {
  background-color: #7b1fa2;
}


